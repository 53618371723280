body {
  margin: 0;
  font-family: 'Inter', apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
}

/** Hide Osano Cookie Button */
button.osano-cm-window__widget {
  display: none;
}

*,
::before,
::after {
  box-sizing: border-box;
}

/* Beamer icon styles */

.beamer_defaultBeamerSelector {
  inset: 14px auto 0 345px !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggZD0iTTEyNS43NSAyNTMuNTgycy0zNS4xMDYgMzUuMTQ2LTY4LjEyMyA2OC4xNjNjLTMxLjQxMSAzMS4zNy0zMS40MTEgODIuMjM3IDAgMTEzLjYwNyAzMS4zMjkgMzEuMzc4IDgyLjI2MSAzMS4zNzggMTEzLjU5IDAgMzMuMDE4LTMzLjAxNyA2OC4yMDQtNjguMTYzIDY4LjIwNC02OC4xNjNMMTI1Ljc1IDI1My41ODJ6TTI4OS44NzkgNDE3LjcxMWwtNTAuNDU3LTUwLjUyMi01Ni4wNzMgNTYuMDczTDI1Ny4wMjIgNDk3YzE4Ljc5OSAwIDM1Ljc0OS0xMS4zMTEgNDIuOTc5LTI4LjY3MSA3LjE1LTE3LjM1MiAzLjIxMi0zNy4zMzEtMTAuMTIyLTUwLjYxOHpNMTgyLjYyNSAxNSAxMjUuNzUgMjUzLjU4MmwxMTMuNjcyIDExMy42MDcgMjM4LjUwOS01Ni44MDN6IiBzdHlsZT0ic3Ryb2tlLXdpZHRoOjMwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIzMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSIiPjwvcGF0aD48cGF0aCBkPSJNMzg3LjA3MyAyMTkuNDk2YzMxLjQxMS0zMS4zNyAzMS40MTEtODIuMjM3IDAtMTEzLjYwNy0zMS4zMjktMzEuMzctODIuMjYxLTMxLjM3LTExMy41OSAwbDExMy41OSAxMTMuNjA3eiIgc3R5bGU9InN0cm9rZS13aWR0aDozMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMzAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iIj48L3BhdGg+PC9nPjwvc3ZnPg==) !important;
  background-color: transparent !important;
  width: 30px !important;
  height: 30px !important;
  background-size: 100% !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.beamer_icon.active,
#beamerIcon.active {
  top: -8px !important;
  right: -8px !important;
}

/* /Beamer icon styles */

/* Action button dropdown */
/* TODO move to the component's mobile design in the ui-kit */
#fluent-default-layer-host .ms-ContextualMenu-Callout {
  @media screen and (max-width: 767px) {
    left: 22px;
    padding: 0;
  }
}

#fluent-default-layer-host .ms-ContextualMenu-itemText {
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
}

#fluent-default-layer-host .ms-ContextualMenu {
  @media screen and (max-width: 767px) {
    padding: 8px;
  }
}

#fluent-default-layer-host button.ms-ContextualMenu-link {
  @media screen and (max-width: 767px) {
    padding: 12px 8px;
    height: auto;
  }
}
/* /Action button dropdown */

/* Raise the notification toast over sidebar */
.h2o-MessageBarList-root {
  z-index: 9999999;
}

/* Reduced too avoid covering the main nav */
#freshworks-container iframe {
  right: 22px;
  left: auto !important;
  z-index: 999999 !important;
  @media screen and (max-width: 1024px) {
    z-index: 6 !important;
  }
}

/* Orchestrator title appearance animations */
@keyframes title-appearance {
  from {
    transform: translateY(25%);
    color: transparent;
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes description-appearance {
  from {
    color: transparent;
  }
}

/* CSSTransition */
.slide-enter {
  position: absolute;
  transform: translateY(-100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.slide-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.expand-enter {
  max-height: 0;
}

.expand-enter-active {
  max-height: 3000px;
  transition: max-height 600ms ease-in-out;
}

.expand-exit {
  max-height: 3000px;
}

.expand-exit-active {
  max-height: 0;
  transition: max-height 600ms ease-in-out;
}

/* /CSSTransition */
